import { useUserInfluencerTagsStore } from '~/stores/userInfluencerTags'

export default defineNuxtRouteMiddleware(async (to) => {
  const { $localePath, $pinia } = useNuxtApp()
  const { FETCH: FETCH_USER_INFLUENCER_TAGS } =
    useUserInfluencerTagsStore($pinia)

  if (to.query.uid && to.query.event) return navigateTo($localePath('/'))

  await Promise.allSettled([FETCH_USER_INFLUENCER_TAGS()])
})
